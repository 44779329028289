var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"mb-4"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"d-flex align-items-start"},[_c('div',[_c('h4',{staticClass:"card-title mb-1"},[_vm._v(" "+_vm._s(_vm.$t("GERAL.PERFIL"))+" ")])])])]),_c('div',{attrs:{"cols":"4 res-inputs"}},[_c('input-text',{ref:"nome",attrs:{"label":_vm.$t('PERMISSOES.NOME'),"required":""},model:{value:(_vm.form.nome),callback:function ($$v) {_vm.$set(_vm.form, "nome", $$v)},expression:"form.nome"}})],1),_c('div',{staticClass:"card mb-4 mt-4"},[_c('div',{staticClass:"d-flex align-items-start"},[_c('div',[_c('h4',{staticClass:"card-title mb-1"},[_vm._v(_vm._s(_vm.$t("PERMISSOES.ACESSOS")))])])])]),_c('b-card',{staticClass:"mb-4 h-overflow",attrs:{"no-body":""}},[_c('b-table',{attrs:{"head-variant":"light","hover":"","items":_vm.items,"fields":_vm.campos,"current-page":_vm.currentPage,"per-page":_vm.perPage},scopedSlots:_vm._u([{key:"cell(acesso)",fn:function(item){return [_c('div',{staticClass:"flex"},[_vm._v(" "+_vm._s(_vm.items[item.index].label)+" ")])]}},{key:"cell(visualizar)",fn:function(item){return [_c('div',{staticClass:"flex"},[_c('InputCheckbox',{attrs:{"id":'Visualizar' + item.item.claimName,"value":item.item.claimValue &&
                  item.item.claimValue.includes('Visualizar')
                    ? true
                    : false},on:{"onChange":function($event){return _vm.changeAcesso(item.index, 'Visualizar')}}})],1)]}},{key:"cell(criar)",fn:function(item){return [_c('div',{staticClass:"flex"},[_c('InputCheckbox',{attrs:{"id":'Criar' + item.item.claimName,"disabled":(item.item.claimValue &&
                    !item.item.claimValue.includes('Visualizar')) ||
                  !item.item.claimValue
                    ? true
                    : false,"value":item.item.claimValue &&
                  item.item.claimValue.includes('Criar')
                    ? true
                    : false},on:{"onChange":function($event){return _vm.changeAcesso(item.index, 'Criar')}}})],1)]}},{key:"cell(editar)",fn:function(item){return [_c('div',{staticClass:"flex"},[_c('InputCheckbox',{attrs:{"id":'Editar' + item.item.claimName,"disabled":(item.item.claimValue &&
                    !item.item.claimValue.includes('Visualizar')) ||
                  !item.item.claimValue
                    ? true
                    : false,"value":item.item.claimValue &&
                  item.item.claimValue.includes('Atualizar')
                    ? true
                    : false},on:{"onChange":function($event){return _vm.changeAcesso(item.index, 'Atualizar')}}})],1)]}},{key:"cell(excluir)",fn:function(item){return [_c('div',{staticClass:"flex"},[_c('InputCheckbox',{attrs:{"id":'Excluir' + item.item.claimName,"disabled":!item.item.claimValue ||
                  (item.item.claimValue &&
                    !item.item.claimValue.includes('Visualizar'))
                    ? true
                    : false,"value":item.item.claimValue &&
                  item.item.claimValue.includes('Excluir')
                    ? true
                    : false},on:{"onChange":function($event){return _vm.changeAcesso(item.index, 'Excluir')}}})],1)]}}])})],1),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.salvar}},[_vm._v(_vm._s(_vm.$t("GERAL.SALVAR")))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }